import { SanitizeOptions } from './types';
import sharedDOMPurify from './types/shared-dompurify';
/**
 * Sanitizes HTML content with optional custom configuration.
 *
 * @param {string} dirtyHTML - //* The HTML content to sanitize.
 * @param {SanitizeOptions} options - //* Optional. Custom configuration for DOMPurify to enhance or restrict the allowed tags and attributes.
 *
 * @example
 * //* 1. Allow only <b> elements
 * const sanitized1 = sanitizeHTML('<div><b>Bold</b> <i>Italic</i></div>', { ALLOWED_TAGS: ['b'] });
 *
 * @example
 * //* 2. Allow <b> and <q> with style attributes
 * const sanitized2 = sanitizeHTML('<div><b style="color:red">Bold</b> <q style="color:blue">Quote</q></div>', { ALLOWED_TAGS: ['b', 'q'], ALLOWED_ATTR: ['style'] });
 *
 * @example
 * //* 3. Use profiles for safe HTML
 * const sanitized3 = sanitizeHTML('<div><b>Bold</b> <i>Italic</i></div>', { USE_PROFILES: { html: true } });
 *
 * @example
 * //* 4. Use profiles for safe SVG elements and SVG Filters
 * const sanitized4 = sanitizeHTML('<svg><circle cx="50" cy="50" r="40"/></svg>', { USE_PROFILES: { svg: true, svgFilters: true } });
 *
 * @example
 * //* 5. Use profiles for safe MathML elements and SVG
 * const sanitized5 = sanitizeHTML('<math><msqrt><mn>4</mn></msqrt></math>', { USE_PROFILES: { mathMl: true, svg: true } });
 *
 * @example
 * //* 6. Change the default namespace
 * const sanitized6 = sanitizeHTML('<svg><circle cx="50" cy="50" r="40"/></svg>', { NAMESPACE: 'http://*www.w3.org/2000/svg' });
 *
 * @example
 * //* 7. Forbid <style> elements
 * const sanitized7 = sanitizeHTML('<div><style>body { color: red; }</style><p>Text</p></div>', { FORBID_TAGS: ['style'] });
 *
 * @example
 * //* 8. Forbid style attributes
 * const sanitized8 = sanitizeHTML('<div><p style="color:red">Text</p></div>', { FORBID_ATTR: ['style'] });
 *
 * @example
 * //* 9. Add <my-tag> to allowed tags
 * const sanitized9 = sanitizeHTML('<div><my-tag>Custom Tag</my-tag></div>', { ADD_TAGS: ['my-tag'] });
 *
 * @example
 * //* 10. Add my-attr to allowed attributes
 * const sanitized10 = sanitizeHTML('<div my-attr="value"><p>Text</p></div>', { ADD_ATTR: ['my-attr'] });
 *
 * @example
 * //* 11. Prohibit ARIA attributes
 * const sanitized11 = sanitizeHTML('<div aria-label="label"><p>Text</p></div>', { ALLOW_ARIA_ATTR: false });
 *
 * @example
 * //* 12. Prohibit HTML5 data attributes
 * const sanitized12 = sanitizeHTML('<div data-custom="value"><p>Text</p></div>', { ALLOW_DATA_ATTR: false });
 *
 * @returns {string} - The sanitized HTML content.
 */
export const sanitizeHTML = (
  dirtyHTML: string,
  options?: SanitizeOptions,
): string => {
  const config: DOMPurify.Config = {
    RETURN_DOM: false,
    ...options,
  };

  const sanitizedContent = sharedDOMPurify.sanitize(dirtyHTML, config);

  // Ensure the return type is string
  if (typeof sanitizedContent !== 'string') {
    throw new Error('Sanitized content is not a string');
  }

  return sanitizedContent;
};
